<template>
	<div class="public">
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/public'>公告管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'公告'}}</el-breadcrumb-item>
	    <el-form label-width="140px" v-if="isShow" class="form-con-center2">
    </el-breadcrumb>-->
		<el-form class="" :rules="dataRules" label-width="140px" :model="form" ref="form" v-loading="dataListLoading">
			<el-form-item v-if="edit" label="编号" prop>{{form.modelNumber}}</el-form-item>
			<el-form-item label="AI测评模型名称" prop="modelName">
				<el-input placeholder="请输入" v-model.trim="form.modelName" maxlength="100" show-word-limit
					class="wd350"></el-input>
			</el-form-item>
			<el-form-item v-if="!edit" label="模型编号" prop="modelNumber">
				<el-input placeholder="请输入" v-model.trim="form.modelNumber" maxlength="30" show-word-limit
					class="wd350"></el-input>
			</el-form-item>
			<el-form-item label="诊断病种" prop="diseaseType">

				<el-select placeholder="请选择" v-model="form.diseaseType" clearable class="wd350">
					<el-option v-for="(item,index) in typeList" :key="index" :value="item.value"
						:label="item.name"></el-option>
				</el-select>


			</el-form-item>
			<el-form-item  label="采集数据类型" required>
			   <el-radio v-model="form.collectType" label="1">应激反应数据</el-radio>
			     <el-radio v-model="form.collectType" label="2">舌苔图片数据</el-radio>
			</el-form-item>
			<el-form-item  v-if="form.collectType==1" label="刺激源形式" required>
				<el-checkbox-group @change="setCheck" v-model="checkList">
					<el-checkbox label="视频观看"></el-checkbox>
					<el-checkbox label="文字朗读"></el-checkbox>

				</el-checkbox-group>
				<div v-if="checkList.includes('视频观看')" class="uploadArea">
					<div class="tip">请上传用于被测评人观看的视频文件</div>
					<el-upload v-if="fileList.length==0" style="" class="upload-demo-new" accept=".mp4"
						:action="actionUrl" :file-list="fileList" :show-file-list="false" :limit="1"
						:on-success="handleSuccess" :before-upload="(file)=>{beforeUpload(file,1)}"
						:headers="importHeader">
						<img class="uploadImg" src="~@/assets/img/jinshen/upload.png" alt="">
						<div class="shangchuan">上传</div>
					</el-upload>
					<div v-if="fileList.length==0" slot="tip" class="el-upload__tip">请上传.MP4格式的视频文件</div>
					<div style="width: 370px;display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;"
						v-else>
						<a style="color: #009CFF;height:22px;line-height: 22px;" :href="fileList[0].url" target="_blank"
							rel="noopener noreferrer">{{fileList[0].name}}</a>
						<div style="display: flex;align-items: center;">
							<el-tooltip class="item" effect="dark" content="重新上传" placement="top-start">
								<el-upload accept=".mp4" :action="actionUrl" :show-file-list="false" :limit="1"
									:on-success="handleSuccess" :before-upload="(file)=>{beforeUpload(file,1)}"
									:headers="importHeader">
									<img style="width: 15px;height: 15px;margin-right: 8px;cursor: pointer;"
										src="~@/assets/img/reUpload.png" alt="" />
								</el-upload>

							</el-tooltip>

							<img @click="deleteFileList()" style="width: 15px;height: 15px;cursor: pointer;"
								src="~@/assets/img/del.png" alt="" />
						</div>
					</div>
				</div>
				<div v-if="checkList.includes('文字朗读')" class="uploadArea">
					<div class="tip">请上传用于被测评人朗读的文本文件</div>
					<el-upload v-if="fileList2.length==0" style="" class="upload-demo-new" accept=".txt" :action="actionUrl"
						:show-file-list="false" :file-list="fileList2" :limit="1" :on-success="handleSuccess2"
						:before-upload="(file)=>{beforeUpload(file,2)}" :headers="importHeader">
						<img class="uploadImg" src="~@/assets/img/jinshen/upload.png" alt="">
						<div class="shangchuan">上传</div>
					</el-upload>
					<div v-if="fileList2.length==0" slot="tip" class="el-upload__tip">请上传.txt格式的文档</div>
					<div style="width: 370px;display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;"
						v-else>
						<a style="color: #009CFF;height:22px;line-height: 22px;" :href="fileList2[0].url"
							target="_blank" rel="noopener noreferrer">{{fileList2[0].name}}</a>
						<div style="display: flex;align-items: center;">
							<el-tooltip class="item" effect="dark" content="重新上传" placement="top-start">
								<el-upload accept=".txt" :action="actionUrl" :show-file-list="false" :limit="1"
									:on-success="handleSuccess2" :before-upload="(file)=>{beforeUpload(file,2)}"
									:headers="importHeader">
									<img style="width: 15px;height: 15px;margin-right: 8px;cursor: pointer;"
										src="~@/assets/img/reUpload.png" alt="" />
								</el-upload>

							</el-tooltip>

							<img @click="deleteFileList2()" style="width: 15px;height: 15px;cursor: pointer;"
								src="~@/assets/img/del.png" alt="" />
						</div>
					</div>
				</div>
		
			</el-form-item>

			<el-form-item label="状态">
				<el-radio v-model="form.status" :label="1">启用</el-radio>
				<el-radio v-model="form.status" :label="2">禁用</el-radio>
			</el-form-item>
			<el-form-item style="padding-top: 30px">
				<el-button type="primary" @click="submit" v-points>保存</el-button>
				<el-button @click="back">取消</el-button>
			</el-form-item>

		</el-form>
	</div>
</template>
<script>
	import TinyMce from "@/components/tiny-mce";
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "publicAdd",
		components: {
			TinyMce,
		},
		data() {
			return {
				edit: false,
				actionUrl: '',
				importHeader: {
					token: ""
				},
				fileList: [],
				fileList2: [],
				checkList: ['视频观看'],
				form: {
					modelNumber: "",

					modelName: "",

					diseaseType: "",

					stimulusForm: "",
					collectType: '1',
					status: 1,
				},
				diseasesList: [],
				typeList: [],
				dataRules: {
					modelName: [{
						required: true,
						message: "AI测评模型名称不能为空",
						trigger: "blur"
					}, ],
					modelNumber: [{
						required: true,
						message: "模型编号不能为空",
						trigger: "blur"
					}, ],
					diseaseType: [{
						required: true,
						message: "请选择分类",
						trigger: "change"
					}, ],

				},
				dataListLoading: false,
				type: "",
			};
		},
		async mounted() {
			this.importHeader.token = localStorage.token;
			this.actionUrl = this.$global.baseURL + "/aliyun/oss/upload";
			this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
			let query = this.$route.query;
			if (query.id) {
				this.edit = true;
				this.dataListLoading = true;
				await this.init(query.id);
			}
			await this.getTypeList();

		},
		methods: {
			setCheck(val) {

				console.log(val)
				if (val.length == 1) {
					this.checkList = val
				} else {
					this.checkList = [val[1]]
				}

			},
			handleSuccess2(response, file, fileList) {
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList2 = [obj]
				console.log(this.fileList2)
			},
			handleSuccess(response, file, fileList) {
				console.log(response, "response");
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList = [obj]

			},
			beforeUpload(file, fileType) {
				let fileName = file.name;
				let pos = fileName.lastIndexOf(".");
				let lastName = fileName.substring(pos, fileName.length);
				let type = lastName.toLowerCase();
				if (fileType == "1") {

					if (
						type != ".mp4"

					) {
						this.$message.error(
							"上传文件只能是.mp4 格式!"
						);
						this.fileList = [];
						return false;
					}
					return true;
				} else if (fileType == "2") {
					// let isLt2M = file.size / 1024 / 1024 < 50;
					if (type != ".txt") {
						this.$message.error("上传文件只能是.txt格式!");
						this.fileList2 = [];
						return false;
					}

					return true;
				}
			},
			back() {
				this.$router.back();
			},
			deleteFileList() {
				this.fileList = []
			},
			deleteFileList2() {
				this.fileList2 = []
			},
			async init(id) {

				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/diagnosisModel/info"),
					method: "post",
					data: {
						stringParam1: id
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
						console.log('diagnosisModel/info')
					console.log(res.data)
					this.form = res.data;
					this.checkList = this.form.stimulusForm.split(',')
					this.listPic(id)
					this.form.diseaseType = parseInt(this.form.diseaseType)
					this.dataListLoading = false;
				}

			},
			async getTypeList() {
				let params = {
					stringParam1: "病种",
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dict/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				// console.log(res,'res')
				this.typeList = res.data;
			},
			async listPic(id) {
				if (this.checkList.includes('视频观看')) {
					let params = {
						stringParam1: id,
						intParam1: 118
					};
					// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
					const {
						data: res
					} = await this.$httpAes({
						url: this.$httpAes.adornUrl("/sysPic/listPic"),
						method: "post",
						data: params,
						contentType: 'json',
						headers: {
							'Content-Type': 'application/json',

						},
					});
					this.fileList = JSON.parse(res.data[0].picUrl)
				}
				if (this.checkList.includes('文字朗读')) {
					let params = {
						stringParam1: id,
						intParam1: 119
					};
					// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
					const {
						data: res
					} = await this.$httpAes({
						url: this.$httpAes.adornUrl("/sysPic/listPic"),
						method: "post",
						data: params,
						contentType: 'json',
						headers: {
							'Content-Type': 'application/json',

						},
					});
					this.fileList2 = JSON.parse(res.data[0].picUrl)
				}
			},
			async insertPic(actionId) {

				let params = {
					stringParam1: []

				}

				if (this.checkList.includes('视频观看')) {
					let obj = {
						actionId: actionId,
						picUrl: JSON.stringify(this.fileList),
						picType: 118,
					}
					params.stringParam1.push(obj)
				}
				if (this.checkList.includes('文字朗读')) {
					let obj = {
						actionId: actionId,
						picUrl: JSON.stringify(this.fileList2),
						picType: 119,
					}
					params.stringParam1.push(obj)
				}
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/insertMultiHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				console.log(res, 'res')
				if (res.status) {
					this.$router.go(-1)
				}
			},
			async deleteHousePic(id) {
				let params = {
					stringParam1: id,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/deleteHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

			},

			async submit() {
				if ( this.form.collectType=='1'&& this.checkList == 0) {
					this.$message.error('请选择刺激源形式')
					return
				}
				if (this.checkList.includes('视频观看') && this.fileList.length == 0) {
					this.$message.error('请上传视频文件')
					return
				}
				if (this.checkList.includes('文字朗读') && this.fileList2.length == 0) {
					this.$message.error('请上传文本文件')
					return
				}
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						let form = this.form;
						form.stimulusForm = this.checkList.join(',')
console.log(form);
						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								form.id ? "/diagnosisModel/update" : "/diagnosisModel/add"
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							this.$message.success(res.msg);
							if( this.form.collectType=='1'){
								if (form.id) {
									await this.deleteHousePic(res.data.id)
									await this.insertPic(res.data.id)
								} else {
									this.insertPic(res.data.id)
								}
							}else{
								this.$router.go(-1)
							}
							

						}
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.uploadArea {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 418px;
		// height: 169px;
		border-radius: 2px;
		border: 1px solid #F4F4F4;
		margin-bottom: 16px;

		.tip {
			width: 370px;
			height: 38px;
			background: #FAF6FF;
			border-radius: 2px;
			text-align: center;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A767FD;
			line-height: 38px;
			margin-top: 8px;
			margin-bottom: 15px;

		}

	}

	.upload-demo-new {
		width: 125px;
		height: 61px;
		border-radius: 2px;
		border: 1px solid #DDDDDD;
		border-style: dashed;
		display: flex;
		align-items: center;
		flex-direction: column;

		.uploadImg {
			width: 16px;
			height: 16px;
		}

		.shangchuan {
			height: 22px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 22px;
			margin-top: -6px;
		}
	}
</style>